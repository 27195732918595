<div class="" class="position-fixed d-flex justify-content-end flex-row" style="z-index:999; left: 120px; max-height: 40px">
  <a role="button" (click)="openGPXFileLoad()" class="list-group-item list-group-item-action spot-nav border-0 rounded-bottom mr-2 d-flex justify-content-between align-items-center">
    <fa-icon [icon]="['fas', 'upload']" class="mr-2"></fa-icon>
    <span class="text-muted small mt-1"   style="min-width: 80px;">LOAD GPX</span>
  </a>
</div>

<!-- MAP -->
<div style="height: calc(100vh - 180px);" leaflet [leafletOptions]="options" (leafletMapReady)="onMapReady($event)">
</div>


<!-- <div class="box" *ngIf="points.length > 0"> -->
<div class="box">
  <div class="shadow p-2 w-100">

    <div class="mx-0 px-0 text-right" style="width: 400px">

      <nav>
        <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
          <a class="nav-item nav-link active" id="nav-home-tab" [class.active]="tabIndex == 0" data-toggle="tab" href="javascript:void(0);" (click)="tabIndex = 0" role="button" aria-controls="nav-home" aria-selected="true">Start</a>
          <a class="nav-item nav-link" id="nav-profile-tab" [class.active]="tabIndex == 1" data-toggle="tab" href="javascript:void(0);" (click)="tabIndex = 1" role="button" aria-controls="nav-profile" aria-selected="false">Session Info</a>
          <a class="nav-item nav-link" id="nav-contact-tab" [class.active]="tabIndex == 2" data-toggle="tab" href="javascript:void(0);" (click)="tabIndex = 2" role="tab" aria-controls="nav-contact" aria-selected="false">GPX Points  </a>
        </div>
      </nav>
      <div class="tab-content py-3 mx-2 px-sm-0 text-left" id="nav-tabContent" style="overflow-y: auto; height: 500px;">
        <div class="tab-pane fade text-center" [class.show]="tabIndex == 0" [class.active]="tabIndex == 0" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            <div>
              <h3 class="mb-2 mt-2 px-4">Set the wind direction</h3>
              <!-- <app-wind-direction (onWindClick)="onWindClick($event)"></app-wind-direction> -->
              <app-wind-selector [selection]="winddir" (onWindClick)="onWindClick($event)"></app-wind-selector>
            </div>
        </div>
        <div class="tab-pane fade pr-4 pl-2" [class.show]="tabIndex == 1" [class.active]="tabIndex == 1" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            <div class="text-muted small" *ngIf="tacks.length == 0 && gybes.length == 0; else analyzeInfo">
              <h3 class="mb-2 mt-2">Analyze</h3>
              <p>
                No GPX data analyzed.
              </p>
              <p *ngIf="!winddir">
                Before you can analyze your data you must provide the predominant <a href="javascript:void(0);" class="text-underline" (click)="tabIndex = 0"><u>wind direction</u>.</a>
              </p>
              <p>
                Press the `Analyze` button below to analyze your GPX data once the wind is set.
              </p>
            </div>
            <ng-template #analyzeInfo>

              <h3 class="mb-0">Session Info</h3>

              <div class="d-flex justify-content-between align-items-end mx-2">
                  <div class="d-flex justify-content-center flex-fill align-items-end">
                    <div class="text-center d-flex  flex-column">
                      <fa-icon [icon]="['fas', 'arrow-circle-up']" class="d-block" [ngStyle]="{'transform': 'rotate(0deg)'}" style="font-size: 200%"></fa-icon>
                      <small>
                        <b>{{ winddir.toUpperCase() }}</b>
                      </small>
                    </div>
                  </div>
                  <small class="text-right mb-0  flex-fill">
                      <h3 class="mb-0"><b>{{ (info.max_speed * 0.539957).toFixed(2) + 'km/h' }}</b></h3>
                      MAX SPEED
                  </small>
              </div>
              <div class="d-flex justify-content-between mt-2 mx-2">
                  <small>
                      DURATION: <b>{{ info.moving_time }}</b>
                  </small>
                  <small>
                      DISTANCE: <b>{{ (info.distance / 1000).toFixed(2) + 'km' }}</b>
                  </small>
              </div>
              <div class="d-flex justify-content-between mx-2">
                <small>START: <span style="color: #07689F"><b>{{ points[0].meta.time }}</b></span></small>
                <small class="ml-4">END: <span style="color: #F67280"><b>{{ points[points.length-1].meta.time }}</b></span></small>
              </div>
              <div class="d-flex justify-content-between mx-2">
                <small>AVG TEMP: <span><b>{{ info.avg_temp }}</b></span></small>
                <small>Date: <span><b>{{ info.start_time_locale }}</b></span></small>
              </div>


              <div class="card h-100 rounded-0 w-100 mt-2" (click)="onSelect(item)">
                <div class="row mx-0 px-0">
                    <div class="col-sm-5 mx-0 px-0 d-flex align-items-center mt-2">
                      <div class="card-body px-4 pt-3 pb-2 mt-0">
                        <h4  style="line-height: 0.5; font-size: 300%;" [style.color]="colors.domain[3]"><b>{{ gybeLength }}</b></h4>
                        <h3 class="mb-0">GYBES</h3>
                      </div>
                    </div>
                    <div class="col-sm-7 d-flex align-items-center">
                      <div>
                          <p class="mb-0">
                            Fastest
                          </p>
                          <hr class="m-0 mb-3" />
                          <p class="mb-0" style="font-family: Monospace; line-height: 1"><small>ENTRY </small><label class="badge badge-dark py-0"><b>24 kn</b></label></p>
                          <p class="mb-0" style="font-family: Monospace;  line-height: 1"><small>EXIT &nbsp;</small><label class="badge badge-dark py-0"><b>16 kn</b></label></p>
                      </div>
                    </div>
                </div>
                <div class="card-footer text-center text-white py-1" [style.backgroundColor]="colors.domain[3]">
                </div>
              </div>


              <div class="card h-100 rounded-0 w-100 mt-2" (click)="onSelect(item)">
                <div class="row mx-0 px-0">
                    <div class="col-sm-5 mx-0 px-0 d-flex align-items-center mt-2">
                      <div class="card-body px-4 pt-3 pb-2 mt-0">
                        <h4  style="line-height: 0.5; font-size: 300%;" [style.color]="colors.domain[2]" ><b>{{ tackLength }}</b></h4>
                        <h3 class="mb-0">TACKS</h3>
                      </div>
                    </div>
                    <div class="col-sm-7 d-flex align-items-center">
                      <div>
                          <p class="mb-0">
                            Fastest
                          </p>
                          <hr class="m-0 mb-3" />
                          <p class="mb-0" style="font-family: Monospace; line-height: 1"><small>ENTRY </small><label class="badge badge-dark py-0"><b>13 kn</b></label></p>
                          <p class="mb-0" style="font-family: Monospace;  line-height: 1"><small>EXIT &nbsp;</small><label class="badge badge-dark py-0"><b>08 kn</b></label></p>
                      </div>
                    </div>
                </div>
                <div class="card-footer text-center text-white py-1" [style.backgroundColor]="colors.domain[2]">
                </div>
              </div>
          <!--      <div class="card h-100 ml-2 rounded-0" (click)="onSelect(item)">
                  <div class="card-body px-4 pt-3 pb-2">
                    <h4 class="text-center" style="line-height:1; font-size: 350%" [style.color]="colors.tack"><b>{{ tackLength }}</b></h4>
                    <p class="mb-0">
                        Fastest
                    </p>
                    <hr class="m-0 mb-2" />
                    <p class="mb-0"><label class="badge badge-dark mr-1"><b>24 kn</b></label> <small>ENTRY</small></p>
                    <p class="mb-0"><label class="badge badge-dark mr-1"><b>16 kn</b></label> <small>EXIT</small></p>
                  </div>
                  <div class="card-footer text-center text-white" [style.backgroundColor]="colors.domain[3]">
                      TACK
                  </div>
                </div>-->


              <div class="d-flex justify-content-center mt-2" style="font-size: 60%">
                POWERED BY ISIVUNO
              </div>
            </ng-template>

        </div>
        <div class="tab-pane fade w-100 d-flex" *ngIf="tabIndex == 2"  [class.show]="tabIndex == 2" [class.active]="tabIndex == 1" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
          <!-- <h3 class="mb-2 mt-2 px-4">GPX Points</h3> -->
          <div class="list-group w-100">
            <button
            [id]="'gpx-point-' + i"
            class="list-group-item list-group-item-action small"
            (click)="selectPoint(i);"
            (mouseover)="onMouseOverPoint(i)"
            (mouseout)="onMouseOutPoint(i)"
            *ngFor="let p of points; let i = index">
            {{ i }}. {{ p.meta.time }} [{{ p.meta.speed_kn?.toFixed(1) }} kn]
            <span *ngIf="bearings[i]"> | Bearing: {{ bearings[i] }}</span>
            <span *ngIf="crossProducts[i]"> | {{ crossProducts[i] }}</span>
          </button>
        </div>
        </div>
        <div class="tab-pane fade" [class.show]="tabIndex == 3" [class.active]="tabIndex == 3" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
          Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
        </div>
        <div class="tab-pane fade" [class.show]="tabIndex == 4" [class.active]="tabIndex == 4" id="nav-about" role="tabpanel" aria-labelledby="nav-about-tab">
          Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
        </div>
      </div>

      <button class="btn btn-outline-primary mr-2" (click)="reset()" [attr.disabled]="!winddir && tabIndex == 1 ? true : null">
          <fa-icon [icon]="['fas', 'redo-alt']" class="mr-1"></fa-icon>
          Reset
      </button>
      <button class="btn btn-outline-primary" (click)="calcGybesAndTacks()" [attr.disabled]="!winddir ? true : null">
          <fa-icon [icon]="['fas', 'calculator']" class="mr-1"></fa-icon>
          Analyze
      </button>
    </div>
  </div>
</div>

<app-modal-file #modal
  dismissLabel="Close"
  acceptLabel="Upload"
  title="Please choose a GPX file to upload"
  (fileUploadEvent)="fileUploaded($event)"
>

</app-modal-file>
