import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { User, GFSData } from '@app/_models';
import { APIService } from '@app/_services';
import { faArrowCircleUp, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'gfs-component',
  templateUrl: './gfs.component.html',
  styleUrls: ['./gfs.component.scss']
})
export class GfsComponent  implements OnChanges
{

  data = [];
  apiUser: User;
  last_updated:string;
  loading: boolean = false;
  weekday: string[] = [
    "SUN",
    "MON",
    "TUE",
    "WED",
    "THU",
    "FRI",
    "SAT"
  ];

  @Input() title = 'South African Weather Data';
  @Input() showInfo: boolean = true;
  @Input() showPoweredBy:boolean = true;
  @Input() cardTitleClass:string = 'mt-3';
  @Input() bodyClass:string = 'shadow';
  @Input() hid:string = 'gfs13-001';

  // icon
  faMapMarkerAlt = faMapMarkerAlt;
  faArrowCircleUp = faArrowCircleUp;

  constructor(
    public api: APIService
  ) {
  }

  ngOnChanges(changes: SimpleChanges)
  {
    const currentItem: any = changes.hid;

    if (currentItem && currentItem.currentValue != currentItem.previousValue)
    {
      this.data = [];
        this.loading = true;
        this.api.getAll(currentItem.currentValue);
    }
  }

  ngOnInit(): void
  {
    this.loading = true;
    console.log('[GfsComponent] Fetch new data')
    this.api.getAll(this.hid);
    this.api.gfsdata.subscribe((x) =>
    {
      // console.log(x)
      if (x == null)
      {
        this.data = [];
        // console.log('[GfsComponent][api] No data');
        this.loading = false;
        return;
      }
      // console.log('[GfsComponent][gfsdata] ', x.length)
      // x.reverse();
      this.data = x.reverse();

      let updated = '';
      if (x.length > 0)
      {
        updated = x[0].date;
        console.log(updated)
      }
      else this.showPoweredBy = false;

      this.last_updated = this.api.lastUpdatedGFSData;
      this.loading = false;

    },
    e =>
    {
      console.log(e)
    });
  }
}
