import { BrowserModule } from '@angular/platform-browser';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AppRoutingModule } from '@app/app-routing.module';

import { FlowModule } from './flow/flow.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { firebase, firebaseui, FirebaseUIModule } from 'firebaseui-angular';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { PrivacyComponent } from './privacy/privacy.component';
import { ProductsComponent } from './landing/products/products.component';
import { AboutComponent } from './landing/about/about.component';
import { LandingComponent } from './landing/landing.component';
import { ContactComponent } from './contact/contact.component';

import { BasicAuthInterceptor, ErrorInterceptor } from './_helpers';

import { SawineComponent } from './landing/products/sawine/sawine.component';
import { DatavizComponent } from './landing/products/dataviz/dataviz.component';
import { RvcComponent } from './landing/products/rvc/rvc.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { VideoComponent } from './ui/video/video.component';
import { ChatComponent } from './landing/chat/chat.component';
import { MapComponent } from './map/map.component';

import Amplify, { Auth, Storage } from 'aws-amplify';
import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    {
      scopes: [
        'public_profile',
        'email',
        // 'user_likes',
        // 'user_friends'
      ],
      customParameters: {
        'auth_type': 'reauthenticate'
      },
      provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID
    },
    firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    // firebase.auth.GithubAuthProvider.PROVIDER_ID,
    // {
    //   requireDisplayName: false,
    //   provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
    // },
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
  ],
  tosUrl: '<your-tos-link>',
  privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
};

@NgModule({
  declarations: [
    AppComponent,
    PrivacyComponent,
    ProductsComponent,
    AboutComponent,
    LandingComponent,
    ContactComponent,
    SawineComponent,
    DatavizComponent,
    RvcComponent,
    NotfoundComponent,
    VideoComponent,
    ChatComponent,
    MapComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    LeafletModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    FormsModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    SharedModule,
    FlowModule
  ],
  exports: [

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6Lc2288UAAAAAKvpQLxsoStJo44iyKuYFWqIzvDb" }],
  bootstrap: [AppComponent]
})
export class AppModule {}
