/**
{
            "hour": "001",
            "wind_gust_kn": 14,
            "windspeed_kn": 9,
            "winddir_label": "SSE",
            "winddir": 167.74920802526566,
            "temp_c": 22.17434000000003,
            "date": "2021-02-09T07:00:00.000Z"
        },
*/
import {Deserializable} from "./deserializable";
export class GFSData implements Deserializable
{
    hour: string;
    wind_gust_kn: string;
    windspeed_kn: string;
    winddir_label: string;
    winddir: string;
    temp_c: string;
    date: string;

  deserialize(input: any)
  {
    Object.assign(this, input);
    return this;
  }
}
