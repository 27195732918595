import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  isplaying:boolean = false;
  thumbnail:string = './assets/dataviz-2.jpg';
  videosrc_mp4:string = './assets/dataviz-b.mp4';

  constructor() { }

  ngOnInit(): void {
  }


}
