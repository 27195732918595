import { Component } from '@angular/core';
import { AuthenticationService, APIService } from './_services';
import { User, GFSData } from './_models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent
{
  title = 'Isivuno';
  currentUser: User;
  toggleLogin:boolean = false;

  constructor(

    public authenticationService: AuthenticationService

  )
  {
    this.authenticationService.currentUser.subscribe(x =>
    {
      this.currentUser = x;
      console.log('[AppComponent] User localstorage: ', x)
      this.toggleLogin = false;
    });
  }

  logout()
  {
    this.authenticationService.logout();
  }

  signInSuccessWithAuthResult(user)
  {
    console.log(user)
  }
}
