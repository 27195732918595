<!-- EXISTING SINGLE FILE PREVIEW -->
<div class="files-list" *ngIf="uploaded_image && files.length == 0">
  <div class="single-file" >
    <img [src]="uploaded_image.filePath" class="me-4" height="45px" alt="file">
    <mat-icon class="mx-4" *ngIf="filePath.includes('.mp4')">videocam</mat-icon>
    <div class="info">
      <h4 class="name">
        {{ uploaded_image?.name }}
      </h4>
      <p class="size">
        {{ formatBytes(uploaded_image?.size) }}
      </p>
      <app-progress [progress]="uploaded_image?.progress"></app-progress>
    </div>
    <img src="assets/ic-delete-file.svg" class="delete" width="20px" alt="file" (click)="deleteFile(0)">
  </div>
</div>

<!-- UPLOAD CONTAINER -->
<div class="container d-flex justify-content-between align-items-center" appDnd (fileDropped)="onFileDropped($event)" *ngIf="!uploaded_image && files.length == 0">
  <div class="d-flex align-items-center">
    <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
    <img src="assets/ic-upload-file.svg" alt="" style="height: 50px" class="float-left p-2 mx-2">
    <span class="">Drag and drop file here</span>
  </div>
  <p class="mb-0"> --- or ---</p>
  <button class="btn btn-dark" for="fileDropRef">Browse for file</button>
</div>

<!-- New UPloads -->
<div class="files-list" *ngIf="files.length > 0">
 <div class="single-file" *ngFor="let file of files; let i = index">
   <img [src]="filePath" class="me-4" height="45px" alt="file" *ngIf="filePath?.includes('.png') || filePath?.includes('.jpg') || filePath?.includes('.jpeg') || filePath?.indexOf('base64') != -1" onerror="this.src='/assets/blank.gif'">
   <!-- <mat-icon class="me-2" *ngIf="filePath?.includes('.mp4')">videocam</mat-icon> -->
   <fa-icon *ngIf="filePath?.toLowerCase().includes('.mp4')" [icon]="['fas', 'video']" class="mr-2"></fa-icon>
   <fa-icon *ngIf="filePath?.toLowerCase().includes('.dat')" [icon]="['fas', 'database']" class="mx-2"></fa-icon>
   <fa-icon *ngIf="filePath?.toLowerCase().includes('.gpx')" [icon]="['fas', 'database']" class="mx-2"></fa-icon>
   <div class="error" *ngIf="message">
      {{ message }}
   </div>
   <div class="info" *ngIf="!message">
     <h4 class="name">
       {{ file?.name }}
     </h4>
     <p class="size">
       {{ formatBytes(file?.size) }}
     </p>
     <app-progress [progress]="file?.progress"></app-progress>
   </div>
   <img src="assets/ic-delete-file.svg" class="delete" width="20px" alt="file" (click)="deleteFile(i)">

 </div>
</div>
