import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm = new FormGroup({
      fullname: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      mobile: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required),
      recaptchav3_token: new FormControl('')
    });
  submitted:boolean = false;
  errors: string[] = [];
  message = '';

  constructor(
    private http: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service
  ) { }

  ngOnInit(): void {
  }

  public onSubmit(): void
  {
    this.submitted = true;
    this.contactForm.disable();
    this.recaptchaV3Service.execute('contactSubmit').subscribe(
      (token) =>
      {
          // console.log(token)
          // 'this.user' contains the data of the user we want to create. Add the received token

          this.contactForm.patchValue({recaptchav3_token: token});
          this.submitForm(token);  // This sends the user data to the backend
      },
      (error) => {
          this.errors = [ 'Error trying to verify request (reCaptcha v3)' ];
      });
  }

  submitForm(token): void
  {
    let formData = {
      "fullname": this.contactForm.get("fullname").value,
      "email": this.contactForm.get("email").value,
      "message": this.contactForm.get("message").value,
      "token": token,
    }
    let result = this.http.post<any>(`${environment.RECAPTCHA}`, formData);
    result.subscribe(
      x =>
      {
        console.log(x);
        this.message = x.message;
        this.submitted = false;
      },
      e => {console.log(e); this.errors = [e]; this.submitted = false; this.contactForm.enable();}
    );
  }
}
