import { Deserializable } from "./deserializable";

export class Spot implements Deserializable
{
    id: string;
    boundary: number[];
    name: string;
    area: string;
    region: string;
    country: string;
    selected: boolean;
    hids:string[];

    deserialize(input: any)
    {
      Object.assign(this, input);
      return this;
    }
}
