import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SharedModule } from '@app/shared/shared.module';
import { SpotSelectorComponent } from './spot-selector/spot-selector.component';
import { GpxViewerComponent } from './gpx-viewer/gpx-viewer.component';
import { WindDirectionComponent } from './wind-direction/wind-direction.component';
import { ArcChartComponent } from './arc-chart/arc-chart.component';
import { WindSelectorComponent } from './wind-selector/wind-selector.component';


@NgModule({
  declarations: [
    SpotSelectorComponent,
    GpxViewerComponent,
    WindDirectionComponent,
    ArcChartComponent,
    WindSelectorComponent,
  ],
  imports: [
    CommonModule,
    LeafletModule,
    SharedModule,
    NgxChartsModule
  ]
})
export class FlowModule { }
  
