import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { AuthenticationService, APIService } from '@app/_services';
import { Spot } from '@app/_models';
import { LocalStorageService } from '@app/_services';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';

import "leaflet/dist/leaflet.css";
import * as L from "leaflet";


const provider = new OpenStreetMapProvider({
  params: {
    'accept-language': 'en', // render results in Dutch
    countrycodes: 'za', // limit search results to the Netherlands
    addressdetails: 0, // include additional address detail parts
  },
});

@Component({
  selector: 'app-spot-selector',
  templateUrl: './spot-selector.component.html',
  styleUrls: ['./spot-selector.component.scss']
})
export class SpotSelectorComponent implements OnInit {

  map:any | undefined;
  grid:any | undefined;
  spotLayer:any | undefined;
  tempLayer:any | undefined;
  markerLayer:any | undefined;
  options = {
     layers: [
       L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png', {
      	maxZoom: 20,
      	attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
      })
     ],
     zoom: 10,
     center: L.latLng( -33.9416268, 18.4632142)
   };

  gfswave = 1/6;
  gfswind = 1/8;
  gfstype:number = 0;
  spots:Spot[] = [];
  loading:boolean = true;
  spotArr:any = [];
  search:any = [];
  timeoutID:any;
  showForecast:number = 0;
  searchloading:boolean = false;

  forecastTitle:string = '';
  spotTitle:string = 'New Spot Info';

  selectedHID:string = 'gfs13-001';
  spotBodyClass:string = 'p-2 shadow-none';

  myIcon = L.icon({
    iconUrl: '/assets/location_on_black_24dp.svg',
  });

  searchForm: FormGroup = new FormGroup({
      searchValue: new FormControl('', Validators.required),
  });

  spotInfoForm: FormGroup = new FormGroup({
      name:  new FormControl('', Validators.required),
      bb:  new FormControl('', Validators.required),
      region: new FormControl('', Validators.required),
      province: new FormControl('', Validators.required),
      hids: new FormControl('', Validators.required)
  })
  config: any = {
    bb: [],
    gfstype: 3,
    spot: {
      name: '',
      region: '',
      province: '',
      hids: ''
    }
  };

  colorList:string[] = [
    'primary',
    'secondary',
    'accent',
    'accent-pink'
  ]

  constructor(
      private ngZone: NgZone,
      private api: APIService,
      private ls: LocalStorageService,
      private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void
  {
      this.loading = true;
      /*this.authenticationService.apiUser.subscribe(x =>
      {
          console.log(x);
          if (x != null)
          {
              // All good
              console.log('[SpotsComponent] Fetch new data')
          }
      });*/
      this.initConfig();
      this.api.getSpots();
  }

  initConfig()
  {
    this.config = this.ls.getItemAsJSON('spots', JSON.stringify(this.config));
    this.spotInfoForm.patchValue(this.config.spot);
    console.log(this.spotInfoForm.value)
    this.gfstype = this.config.gfstype;
    this.spotInfoForm.valueChanges.subscribe(x =>
    {
        this.config.spot = x;
        clearTimeout(this.timeoutID);
        this.timeoutID = setTimeout(() =>
        {
          this.ls.setItemFromJSON('spots', this.config);
        }, 500);
    });
  }

  async onMapReady(map)
  {
      this.map = map;
      this.grid = L.layerGroup();
      this.spotLayer = L.layerGroup();
      this.tempLayer = L.layerGroup();
      this.markerLayer = L.layerGroup();
      let icon = new L.Icon.Default();
      icon.options.shadowSize = [0,0];
      icon.options.shadowUrl = 'assets/blank.gif';
      map.on('zoomend', () =>
      {
          // this.grid.clearLayers();
          // this.drawGrid(map);

          // console.log('--- temp')
          // console.log(this.tempLayer.getLayers())
          this.redraw();
      });
      map.dragging.enable();
      this.drawGrid(map);
      this.api.spots.subscribe(x =>
      {
        if (x != null)
        {
          // Parse spots
          console.log('---- SPOTS ----');
          this.spots = x;
          this.drawSpots();
        }
      });
      this.spotLayer.addTo(this.map);
      this.tempLayer.addTo(this.map);
      this.markerLayer.addTo(this.map);

      // if (this.config.bb) this.map.fitBounds(this.config.bb);
  }

  drawSpots()
  {
    this.loading = true;
    this.spotArr = [];
    for (let index = 0; index < this.spots.length; index++)
    {
        const spot = this.spots[index];
        const c1 = L.latLng(spot.boundary[2], spot.boundary[1]);
        const c2 = L.latLng(spot.boundary[3], spot.boundary[0]);
        const bounds = L.latLngBounds(c1, c2);
        this.spotArr.push(this.addRectangle(bounds, {fillOpacity: 0.0, opacity: 0.05, created: false, isspot: true, spotIndex: index}, this.spotLayer));
    }
    this.loading = false;
  }

  showSpot(i)
  {

      this.spots[i].selected = !this.spots[i].selected;
      this.spotArr[i].setStyle({
        fillOpacity: this.spotArr[i].options.fillOpacity == 0.0 ? 0.5 : 0.0
      });
  }

  onSearch($event)
  {
    clearTimeout(this.timeoutID);
    if (this.searchForm.value.searchValue.length == 0)
    {
        this.search = [];
        return;
    }
    if (this.searchForm.value.searchValue.length > 3)
    {
        this.timeoutID = setTimeout(async() =>
        {
          this.searchloading = true;
          this.search = await provider.search({ query: this.searchForm.value.searchValue });
          this.searchloading = false;
        }, 300);
    }
  }

  dropMarker(result)
  {
    const marker = L.marker([result.y, result.x], {icon: this.myIcon})
    marker.on({
      click: async(e) =>
      {
          console.log(result.bounds)
      }
    })
    marker.addTo(this.markerLayer);
  }


  clearMarkers()
  {
    this.markerLayer.clearLayers();
  }

  clearTempSpots()
  {
    this.tempLayer.clearLayers();
  }

  redraw()
  {
    for (let index = 0; index < this.spots.length; index++)
    {
      this.ngZone.run(() => { this.spots[index].selected = false; });
    }
    this.tempLayer.clearLayers();
    this.spotLayer.clearLayers();
    this.grid.clearLayers();
    this.drawGrid(this.map);
    this.drawSpots();
  }

  copyBB()
  {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.spotInfoForm.value.bb;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  roundBB()
  {
    let str = '';
    const arr = this.spotInfoForm.value.bb.split(',');
    for (let index = 0; index < arr.length; index++)
    {
      const element = Number.parseFloat(arr[index]).toFixed(2);
      str += element + ',';
    }
    str = str.slice(0, -1);
    this.spotInfoForm.patchValue({bb: str});

  }

  calcValue()
  {
    let val = 4;
    let numerator = 1;
    switch (this.gfstype)
    {
        case 0: val = 2; break;
        case 1: val = 4; break;
        case 2: val = 6; break;
        case 3: val = 8; break;
        case 4: val = 25;
          numerator = 4;
          break;
    }
    return {value: val, numerator: numerator};
  }

  drawTemp()
  {
    const arr = this.spotInfoForm.value.bb.split(',');
    const c1 = L.latLng(parseFloat(arr[2]), parseFloat(arr[0]));
    const c2 = L.latLng(parseFloat(arr[3]), parseFloat(arr[1]));
    const bounds = L.latLngBounds(c1, c2);
    const rect = this.addRectangle(bounds, {fillOpacity: 0.5, color: 'rgb(255, 146, 51)', opacity: 0.05, created: true, selected: true}, this.tempLayer);
    console.log(rect)
  }

  drawGrid(map)
  {
    const precision = this.calcValue();
    const resolution = precision.numerator / precision.value;

    let centerBounds = L.latLngBounds([]);
    centerBounds.extend(map.getBounds().getSouthWest());
    centerBounds.extend(map.getBounds().getNorthEast());

    let startlat = Math.round(centerBounds.getSouthWest().lat * precision.value) / precision.value;
    let endlat = Math.round(centerBounds.getNorthEast().lat * precision.value) / precision.value;
    let startlng = Math.round(centerBounds.getSouthWest().lng * precision.value) / precision.value;
    let endlng = Math.round(centerBounds.getNorthEast().lng * precision.value) / precision.value;

    let arr = [];
    let col = 0;
    let row = 0;
    let count = 0;
    let rectBounds = L.latLngBounds([[startlat-resolution, startlng-resolution], [endlat+resolution, endlng+resolution]]);

    for (let k = startlat-resolution; k <= endlat+resolution; k += resolution)
    {
      col = 0;
      for (let i = startlng-resolution; i <= endlng+resolution; i +=resolution)
      {
        let latlng = L.latLng({lat: k, lng: i});
        arr.push(latlng);
        col++
        count++;
      }
      row++
    }

    for (let i = 0; i < col; i++)
    {
      for (let k = 0; k < row; k++)
      {
        let bounds = L.latLngBounds([]);
        if (k+1 < row && i+1 < col)
        {
          bounds.extend(arr[i + col * k]);
          bounds.extend(arr[i+1 + col * k]);
          bounds.extend(arr[i + col * (k+1)]);
          bounds.extend(arr[i+1 + col * (k+1)]);
          this.addRectangle(bounds, {fillOpacity: 0.05, opacity: 0.05, created: false}, this.grid);
        }
      }
    }
    this.grid.addTo(this.map);

  }

  addRectangle(bb, options ,layer)
  {
    const precision = this.calcValue();
    const resolution = precision.numerator / precision.value;
    let created = false;
    let rect = L.rectangle(bb, options);
    let rectSelected = L.rectangle(bb);
    let map = this.map;
    // this.map.dragging.enable();
    rect.on({
      click: (e) =>
      {
        let lat = e.latlng.lat;
        let lng = e.latlng.lng;
        let corner1 = L.latLng(lat - resolution/2, lng - resolution/2);
        let corner2 = L.latLng(lat + resolution/2, lng + resolution/2);
        let newbounds = L.latLngBounds(corner1, corner2);

        if (!e.target.options.created && !e.target.options.isspot)
        {
          this.addRectangle(newbounds, {color: 'rgb(255, 146, 51)', fillOpacity: 0.5, opacity: 0, selected: true, created: true}, this.tempLayer);
          e.target.options.created = true;
        }
        else if (e.target.options.isspot)
        {
            const spot:Spot = this.spots[e.target.options.spotIndex];
            const hid = spot.hids.filter(e => e.indexOf('gfs') != -1);
            if (hid.length == 0)
            {
                // Spot doesn't have GFS HID
                console.log("Spot doesn't have GFS HID");
                return;
            }
            this.ngZone.run(() => {
              this.selectedHID = hid[0];
              this.showForecast = 1;
              this.spotBodyClass = 'p-0';
              this.spotTitle = spot.name;
              this.forecastTitle = spot.area + ', ' + spot.region + ', ' + spot.country;
            });
            let bb = e.target.getBounds().getSouthWest().lng + ',' + e.target.getBounds().getNorthEast().lng + ',';
            bb += e.target.getBounds().getNorthEast().lat + ',' + e.target.getBounds().getSouthWest().lat;
            this.spotInfoForm.patchValue({
                bb: bb
            });
        }
        else if (e.target.options.created && e.target.options.selected)
        {
            this.ngZone.run(() => { this.showForecast = 0; });
            let bb = e.target.getBounds().getSouthWest().lng + ',' + e.target.getBounds().getNorthEast().lng + ',';
            bb += e.target.getBounds().getNorthEast().lat + ',' + e.target.getBounds().getSouthWest().lat;
            this.spotInfoForm.patchValue({
                bb: bb
            });
        }
        e.target.options.selected = !e.target.options.selected;
      },
      touchstart: (e) =>
      {
      },
      mousedown: (e) =>
      {
        if (e.target.options.created) this.map.dragging.disable();
        e.target.options.mousedown = true;

      },
      mousemove: (e) =>
      {
        if (!e.target.options.mousedown) return;
        e.target.options.mousedrag = true;
        let lat = e.latlng.lat;
        let lng = e.latlng.lng;
        let corner1 = L.latLng(lat - resolution/2, lng - resolution/2);
        let corner2 = L.latLng(lat + resolution/2, lng + resolution/2);
        let newbounds = L.latLngBounds(corner1, corner2);
        if (options.selected)
        {
          rect.setBounds(newbounds);
        }
      },
      mouseup: (e) =>
      {
        if (e.target.options.created)
        {
            this.map.dragging.enable();
        }
        e.target.options.mousedown = false;
        if (e.target.options.mousedrag)
        {
          e.target.options.mousedrag = false;
          this.grid.clearLayers();
          this.drawGrid(this.map);
        }
      }
    });
    this.map.on('mouseup',(e) =>
    {
        // this.map.removeEventListener('mousemove');
    })
    layer.addLayer(rect);
    return rect;
  }
}
