<ng-template #modal>
    <div class="modal-header">
        <h5 class="modal-title">{{ title }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismiss()" ngbAutofocus>
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="mb-4">
            <p>Please make a selection</p>
        </div>
        <div *ngIf="loading" class="loader">
          <fa-icon [icon]="['fas','spinner']" [spin]="true"></fa-icon>
        </div>
        <app-file-upload
            prefix="data/"
            [uploaded_image]="uploaded_image"
            (fileUploadEvent)="fileUploaded($event)"></app-file-upload>
    </div>
    <div class="modal-footer" *ngIf="!complete">
        <button type="button"
                class="btn btn-outline-secondary"
                (click)="dismiss()"
        >
            {{ dismissLabel }}
        </button>
    </div>

    <div class="modal-footer" *ngIf="complete">
        <button type="button"
                class="btn btn-secondary"
                (click)="dismiss()"
        >
            All Done
        </button>
    </div>
</ng-template>
