import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  form: FormGroup;

  @Input() multiSelect: boolean;
  @Input() title: string = "Selection";
  @Input() data:any[] = [];
  @Input() icon:boolean = true;
  @Input() acceptLabel: string = '';
  @Input() dismissLabel: string = '';

  @ViewChild('modal') private modalComponent: TemplateRef<ModalComponent>;
  private modalRef: NgbModalRef;
  @Output() onSelect: EventEmitter<string[]> = new EventEmitter();


  loading: boolean = false;
  assignedManager: boolean = false;

  get listFormArray()
  {
    return this.form.controls.list as FormArray;
  }

  clearListFormArray()
  {
    this.form.controls.list = new FormArray([]);
  }

  constructor(
    private formBuilder: FormBuilder,
    private _modalService: NgbModal)
    {
      this.form = this.formBuilder.group({
        list: new FormArray([])
      });

    }


  ngOnInit() {

  }

  private addCheckboxes()
  {
    if (this.form.controls.list.value.length == 0)
    {
      this.form.controls.list = new FormArray([]);
      this.data.forEach(
        () => {
          this.listFormArray.push(new FormControl(false))
        }
      );
    }
  }

  selectUsers()
  {
    const selectedUsers = this.form.value.selectedUsers
    .map((checked, i) => checked ? this.data[i] : null)
    .filter(v => v !== null)
    this.onSelect.emit(selectedUsers);
    this.form.reset();
    this.modalRef.close();
  }

  open(load:boolean = false)
  {
    if (load) {}
    else this.addCheckboxes();
    this.modalRef = this._modalService.open(this.modalComponent);
  }

  close()
  {
    this.modalRef.close();
  }

  dismiss()
  {
    this.modalRef.dismiss();
  }

  clickItem(index: number)
  {
    if(this.multiSelect)
    {
      this.form.value.list[index] = !this.form.value.list[index];
    }
    else
    {
      this.form.value.list = this.form.value.list.map(x => false);
      this.form.value.list[index] = !this.form.value.list[index];
    }
    this.assignedManager = true;
  }

}
