<div class="container-fluid">
  <div class="row">
    <div class="col-3">
  		<a href="/" style="text-decoration: none">
  			<img class="mr-2 float-left" style="margin-top: 2rem" src="/assets/harvest.svg" width="50rem" />
  			<h1 class="logo mt-4 mb-0 pb-0">
  				isivuno
        </h1>
  			<p class="mt-0 small"> The Creative Data Hub
  				<br/>
  				<span class="small text-muted">(Isivuno is an Isizulu word meaning to harvest)</span>
        </p>
      </a>
    </div>
  </div>
<router-outlet></router-outlet>
</div>
<footer class="footer">
  <div class="container">
    <div class="text-muted sm text-center small pt-4">
    	Est. by <a href="https://www.wezside.co.za" target="_blank">wezside</a>
    	<span class="small"> &copy;</span>
    	2016 - 2022
    	<a href="/privacy">
    		| Privacy Policy
      </a>
      <a href="/contact">
    		| Contact Us
      </a>
    	<!--<a href="/terms">
    		| Terms of Service
    	</a>-->
    </div>
  </div>
</footer>
