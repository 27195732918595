<div class="" class="position-fixed d-flex justify-content-end flex-row" style="z-index:999; left: 120px; max-height: 40px">

  <a role="button" (click)="clearMarkers()" class="list-group-item list-group-item-action spot-nav border-0 rounded-bottom mr-2 d-flex justify-content-between align-items-center">
    <img src="/assets/location_on_black_24dp.svg" height="20px" />
    <span class="text-muted small mt-1"   style="min-width: 140px;">CLEAR MARKERS</span>
    <fa-icon [icon]="['fas', 'trash-alt']" class="ml-2"></fa-icon>
  </a>
  <a role="button" (click)="clearTempSpots()" class="list-group-item list-group-item-action  spot-nav border-0 d-flex border-0 rounded-bottom mr-2 justify-content-between align-items-center">
    <span style="width: 13px; height: 13px; background-color: rgb(255, 146, 51)" class="d-block float-left mr-2"></span>
    <span class="text-muted  small mt-1"  style="min-width: 140px;">CLEAR TEMP SPOTS</span>
    <fa-icon [icon]="['fas', 'trash-alt']" class="ml-2" style="color: rgb(255, 146, 51)"></fa-icon>
  </a>
  <a role="button" (click)="redraw()" class="list-group-item list-group-item-action  spot-nav border-0 d-flex border-0 rounded-bottom mr-2 justify-content-between align-items-center">
    <fa-icon [icon]="['fas', 'redo']" class="mx-2" ></fa-icon>
    <!-- <span class="text-muted  small mt-1"  style="min-width: 50px;">RESET</span> -->
  </a>
  <div class="btn-group btn-block btn-group-toggle mr-4" data-toggle="buttons">
    <label class="btn btn-light" [class.active]="gfstype == 0">
      <input type="radio" name="options" id="option2" autocomplete="off" (click)="gfstype = 0; redraw()">
      <small class="mt-1">1/2</small>
    </label>
    <label class="btn btn-light" [class.active]="gfstype == 1">
      <input type="radio" name="options" id="option2" autocomplete="off" (click)="gfstype = 1; redraw()">
      <small class="mt-1">1/4</small>
    </label>
    <label class="btn btn-light" [class.active]="gfstype == 2" (click)="gfstype = 2; redraw()">
      <input type="radio" name="options" id="option3" autocomplete="off">
      <small>1/6</small>
    </label>
    <label class="btn btn-light" [class.active]="gfstype == 3" (click)="gfstype = 3; redraw()">
      <input type="radio" name="options" id="option3" autocomplete="off">
      <small>1/8</small>
    </label>
    <label class="btn btn-light" [class.active]="gfstype == 4" (click)="gfstype = 4; redraw()">
      <input type="radio" name="options" id="option3" autocomplete="off">
      <small>4/25</small>
    </label>
  </div>

  <div class="" class="position-fixed d-flex justify-content-end flex-row" style="z-index:999; right: 20px;">
    <div class="mx-0 px-0" style="width: 400px">
      <div class="list-group" style="overflow-y: auto; max-height: 400px;">
        <li class="list-group-item d-flex justify-content-center align-items-center">
          <fa-icon [icon]="['fas', 'search']" class="mr-2"></fa-icon>
          <span class="small">Search for a new spot</span>
        </li>
        <li class="list-group-item">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">search</span>
            </div>
            <form [formGroup]="searchForm" class="col mx-0 px-0">
              <input type="text" class="form-control" (keyup)="onSearch($event)" formControlName="searchValue" />
            </form>
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-center" *ngIf="searchloading">
          <fa-icon [icon]="['fas','spinner']" [spin]="true"></fa-icon>
        </li>
        <a role="button" class="list-group-item list-group-item-action  d-flex flex-grow justify-content-start align-items-center" [class]="result.selected ? 'active' : ''" *ngFor="let result of search; let i = index" (click)="dropMarker(result)">
          <span class="small">{{ result.label }}</span>
        </a>
      </div>
      <div class="list-group mt-4" style="overflow-y: auto; max-height: vh100;">
        <a role="button" class="list-group-item list-group-item-action d-flex justify-content-start align-items-center text-white" [class]="'spot--' + colorList[1] + '-' + (((i % 10) * 100 + 100))" [class.mt-1]="i > 0" [class.active]="spot.selected"
          *ngFor="let spot of spots; let i = index" (click)="showSpot(i)">
          <fa-icon [icon]="['fas', 'map-marker-alt']" class="mr-2"></fa-icon>
          <span>{{ spot.name }}</span>
        </a>
      </div>
    </div>
  </div>
</div>

<div style="height: calc(100vh - 180px);" leaflet [leafletOptions]="options" (leafletMapReady)="onMapReady($event)">
</div>

<div class="position-fixed" style="top: 0; right: 0; min-width: 4rem; min-height: 5rem;z-index: 9999" *ngIf="loading">
  <div class="gooey">
    <span class="dot"></span>
    <div class="dots">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</div>

<div class="box">
  <div class="shadow p-2 w-100">
    <h3 class="mb-0 mt-2 px-4">{{ spotTitle }}</h3>
    <hr class="mb-4">
    <form [formGroup]="spotInfoForm" class="small px-4" *ngIf="showForecast == 0">
      <div class="form-group d-flex align-items-center">
        <label class="mr-2 mb-0 text-muted" style="width: 80px">Name</label>
        <input type="text" formControlName="name" class="form-control" placeholder="e.g. Haakgat" />
      </div>
      <div class="form-group d-flex align-items-center">
        <label class="mr-2 mb-0 text-muted" style="width: 80px">Region</label>
        <input type="text" formControlName="region" class="form-control" placeholder="e.g. Blouberg" />
      </div>
      <div class="form-group d-flex align-items-center">
        <label class="mr-2 mb-0 text-muted" style="width: 80px">Province</label>
        <input type="text" formControlName="province" class="form-control" placeholder="e.g. Western Cape" />
      </div>
      <div class="form-group d-flex align-items-center">
        <label class="mr-2 mb-0 text-muted" style="width: 80px">Harvester IDs</label>
        <input type="text" formControlName="hids" class="form-control" placeholder="gfs-001,mac-001" />
      </div>
      <hr>
      <div class="form-group">
        <label class="text-muted">Bounding Box</label>
        <div class="input-group">
          <input type="text" formControlName="bb" class="form-control" placeholder="lat,lat,lng,lng" />
          <div class="input-group-append">
            <button class="input-group-text" (click)="copyBB();">
              <fa-icon [icon]="['fas', 'copy']"></fa-icon>
            </button>
            <button class="input-group-text" (click)="drawTemp();">
              <fa-icon [icon]="['fas', 'edit']"></fa-icon>
            </button>
            <button class="input-group-text" (click)="roundBB();">
              <fa-icon [icon]="['fas', 'calculator']"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </form>
    <gfs-component *ngIf="showForecast == 1"
        [title]="forecastTitle"
        [shadowClass]="'shadow-none'"
        [bodyClass]="spotBodyClass"
        [cardTitleClass]="''"
        [showInfo]="false"
        showPoweredBy="false"
        [hid]="selectedHID">
    </gfs-component>
  </div>
</div>
