import { Component, OnInit, Input } from '@angular/core';
import * as d3 from 'd3';
import { IGraphData } from 'src/app/_models/graphdata';

@Component({
  selector: 'app-arc-chart',
  templateUrl: './arc-chart.component.html',
  styleUrls: ['./arc-chart.component.scss']
})
export class ArcChartComponent implements OnInit
{

    @Input()
    data:IGraphData[] = [];

    private svg;
    private margin = 50;
    private width = 750;
    private height = 600;
    private colors;

    constructor() { }

    ngOnInit(): void
    {
      this.createSVG();
    }

    createSVG()
    {
      const radius = Math.min(this.width, this.height) / 2 - 40;
      this.colors = d3.scaleOrdinal()
      .domain(this.data.map(d => d.value.toString()))
      .range(["#c7d3ec", "#a5b8db", "#879cc4", "#677795", "#5a6782"]);

      // The arc generator
      const arc = d3.arc()
        .innerRadius(radius * 0.5)         // This is the size of the donut hole
        .outerRadius(radius * 0.8)
        .startAngle(function(d) { return d.startAngle + Math.PI/4; })
        .endAngle(function(d) { return d.endAngle + Math.PI/4; });

      // append the svg object to the div called figure
      this.svg = d3.select("figure#arc-chart")
          .append("svg")
          .attr("viewBox", "0 0 960 600")
          .append("g")
          .attr("class", "svg-style")
          .attr(
            "transform",
            "translate(" + 960 / 2 + "," + this.height / 2 + ")"
          );

      // Compute the position of each group on the pie:
      const pie = d3.pie<any>().value((d: IGraphData) => Number(d.value));
      pie.sort(null);

      this.svg
          .selectAll('allSlices')
          .data(pie(this.data))
          .join('path')
          .attr('d', arc)
          .attr('fill', d => this.colors(d.data[0]))
          .attr("stroke", "white")
          .style("stroke-width", "2px")
          .style("opacity", 0.7)

      this.svg
        .selectAll('allLabels')
        .data(pie(this.data))
        .join('text')
        .text(d => d.data.Framework)
    }

}
