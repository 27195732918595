<ng-template #modal>
    <div class="modal-header">
        <h5 class="modal-title">{{ title }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="mb-4">
            <p>Please make a selection</p>
        </div>
        <div *ngIf="loading" class="loader">
          <fa-icon [icon]="['fas','spinner']" [spin]="true"></fa-icon>
        </div>
        <form *ngIf="form" [formGroup]="form">
            <div class="list-group mt-4" formArrayName="list">
                <button class="list-group-item list-group-item-action pe-none" *ngFor="let listItem of listFormArray.controls; let i = index" (click)="clickItem(i)">
                    <div class="d-flex">
                        <fa-icon [icon]="['fas','user']" *ngIf="icon"></fa-icon>
                        <span class="ml-4">{{ data[i].value }}</span>
                        <span *ngIf="form.value.list[i]" class="ml-auto"><fa-icon [icon]="['fas','check']"></fa-icon></span>
                    </div>
                </button>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button"
                class="btn btn-outline-secondary rounded-0"
                (click)="dismiss()"
        >
            {{ dismissLabel }}
        </button>
        <button type="button"
                class="btn btn-secondary rounded-0"
                (click)="selectUsers()"
                [disabled]="!assignedManager"
        >
            {{ acceptLabel }}
        </button>
    </div>
</ng-template>
