<div class="row justify-content-center">
		<div class="col-3 d-block-md" style="background-color: black; min-height: 100%"></div>
		<div class="col-12 col-sm-6 px-0">
			<img class="w-100" src="/assets/landing-sm.jpg" />
    </div>
    <div class="col-3 d-block-md" style="background-color: black; min-height: 100%"></div>
</div>
<app-about></app-about>
<app-products></app-products>
<!-- <app-chat></app-chat> -->
