import { Component, OnInit, Input } from '@angular/core';

import { faPlay } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'ui-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  // icon
  faPlay = faPlay;

  @Input()
  isplaying: boolean = true;

  @Input()
  srcmp4:string = '';

  @Input()
  thumbnail:string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
