<div
    style="height: 60vh; margin-top: 3rem"
    leaflet
    [leafletOptions]="options"
    (leafletMapReady)="onMapReady($event)"
    >
</div>


<!--<div id="map-holder">
  <div class="container fill">
    <div id="map"
      leaflet
      [leafletOptions]="options"
      >
    </div>
  </div>
</div>
-->
