import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dataviz',
  templateUrl: './dataviz.component.html',
  styleUrls: ['./dataviz.component.scss']
})
export class DatavizComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
