import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@app/_services/authentication.service';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from '@environments/environment';
import { GFSData, Spot } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class APIService
{
    public lastUpdatedGFSData: string;

    public spots: Observable<Spot[]>;
    private spotSubject: BehaviorSubject<Spot[]>;

    public gfsdata: Observable<GFSData[]>;
    private gfsDataSubject: BehaviorSubject<GFSData[]>;

    constructor(
      private http: HttpClient,
      public authenticationService: AuthenticationService
    )
    {
      this.spotSubject = new BehaviorSubject(null);
      this.gfsDataSubject = new BehaviorSubject(null);
      this.gfsdata = this.gfsDataSubject.asObservable();
      this.spots = this.spotSubject.asObservable();
    }

    getSpots()
    {
      const result = this.http.post<Spot[]>(`${environment.API}/fetch`, {"hid": "spots", "filter_value": "Western Cape"}).pipe(
        map(x =>
        {
          console.log('----- API ------');
          return x['result']['Items'].map(data => new Spot().deserialize(data));
        })
      );
      result.subscribe(x =>
      {
          this.spotSubject.next(x);
      }, e =>
      {
        this.isUnauthorised(e);
      })
    }

    getAll(hid:string):void
    {
      console.log('hid', hid)
      if (!hid || hid == '')
      {
          this.gfsDataSubject.next(null);
          return;
      }

      let d = new Date();
      let hh = Math.floor((d.getHours() - 6) / 6) * 6
      d.setUTCMinutes(0);
      d.setUTCSeconds(0)
      d.setUTCMilliseconds(0)
      d.setUTCHours(hh);
      let result = this.http.post<GFSData[]>(`${environment.API}/gfs`, {"hid": hid, "filter_value": d.toISOString(), "forecast_type": "daily"}).pipe(
        map(data =>
        {
          this.lastUpdatedGFSData = data['last_updated'];
          // console.log('last updated:', data, data['last_updated']);
          return data['result']['Items'].map(data => new GFSData().deserialize(data))
        })
      );
      result.subscribe(x =>
      {
        // console.log('[apiService][getAll] Subscription Result', x);
        if (x.length == 0)
        {
          console.log('>>>>>>>>>> Dont update data');
        }
        this.gfsDataSubject.next(x);
      },
      e =>
      {
        this.isUnauthorised(e);
      });
    }


    isUnauthorised(e)
    {
      console.log(e)
      if (e == 'Unauthorized')
      {
        console.log('api.service', 'Unauthorized')
        // localStorage.removeItem('apiUser');
        this.gfsDataSubject.next(null);
        this.authenticationService.refreshUser();
      }
    }
}
