<div class="row my-4 justify-content-center d-flex flex-column-reverse flex-md-row">
  <div class="col-lg-3 col-md-6 col-12 mt-md-4">
    <app-map></app-map>
  </div>
  <div class="col-lg-3 col-md-6 col-12 my-md-4">
    <div class="position-absolute w-100" style="left: 50%; top: 50%;" *ngIf="submitted">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <h3>Contact us</h3>
    <p>Please contact us if you have questions or are interested in using any one of our products.
    <div *ngIf="errors.length > 0" class="alert alert-danger rounded-0">
      Something went wrong trying to send the message.
    </div>
    <div *ngIf="message != ''" class="alert alert-success rounded-0">
      {{ message }}
    </div>
    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" *ngIf="message == ''">
      <div class="form-group">
        <label>
          Fullname
        </label>
        <input type="text" formControlName="fullname" class="form-control" required>
      </div>

      <div class="form-group">
        <label>
          Email
        </label>
        <input type="text" formControlName="email" class="form-control" required>
      </div>

      <div class="form-group">
        <label>
          Tel/Mobile
        </label>
        <input type="tel" formControlName="mobile" class="form-control" required>
      </div>

      <div class="form-group">
        <label>
          Message
        </label>
        <textarea class="form-control" formControlName="message" rows="8" cols="80" required></textarea>
      </div>

      <div class="form-group" style="padding-bottom: 5rem">
        <input type="hidden" formControlName="recaptchav3_token" class="form-control">
        <button type="submit" class="btn btn-outline-dark rounded-0 px-4" [disabled]="!contactForm.valid">
          Send
        </button>
      </div>
    </form>
  </div>
</div>
