import { Component, OnInit } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import "leaflet/dist/leaflet.css";

import * as L from "leaflet";

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  options = {
     layers: [
       L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
       	maxZoom: 19,
       	attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
       })
      //  L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png', {
      // 	maxZoom: 20,
      // 	attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
      // })
     ],
     zoom: 12,
     center: L.latLng(-33.9238841,18.4512921)
   };

  constructor() { }

  ngOnInit(): void {
  }

  onMapReady(map)
  {
    let icon = new L.Icon.Default();
    icon.options.shadowSize = [0,0];
    icon.options.shadowUrl = 'assets/blank.gif';
    let marker = L.marker([-33.9338841,18.4512921], {icon: icon}).addTo(map);
  }
}
