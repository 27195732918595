// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCRoUauaRLYST81RSPLzLHFtj9PCIcw2KM",
    authDomain: "blistering-torch-6755.firebaseapp.com",
    databaseURL: "https://blistering-torch-6755.firebaseio.com",
    projectId: "blistering-torch-6755",
    storageBucket: "blistering-torch-6755.appspot.com",
    messagingSenderId: "885272944412",
    appId: "1:885272944412:web:8a55c45fcefb7ea43ce4d9"
  },
  API: 'https://eclbwdbe8b.execute-api.af-south-1.amazonaws.com/default/api',
  RECAPTCHA: 'https://eclbwdbe8b.execute-api.af-south-1.amazonaws.com/default/web/recaptcha_v3',
  FREE_API_KEY: 'PSMVSFiApO81u9PggJ1cO90KGRZvxTlY1Nyo0qA2'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
