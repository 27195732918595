import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-wind-direction',
  templateUrl: './wind-direction.component.html',
  styleUrls: ['./wind-direction.component.scss']
})
export class WindDirectionComponent implements OnInit {

  data = [
    {"Framework": "East", "Stars": "250"},
    {"Framework": "South", "Stars": "250"},
    {"Framework": "West", "Stars": "250"},
    {"Framework": "North", "Stars": "250"},
   ];
   private svg;
   private margin = 50;
   private width = 750;
   private height = 600;
   // The radius of the pie chart is half the smallest side
   private radius = Math.min(this.width, this.height) / 2 - this.margin;
   private colors;

   @Output()
   onWindClick:EventEmitter<any> = new EventEmitter();


  constructor() { }

  ngOnInit(): void
  {
    this.createColors();
    this.createSvg();
  }

  createColors(): void {
    this.colors = d3.scaleOrdinal()
    .domain(this.data.map(d => d.Stars.toString()))
    .range(["#c7d3ec", "#a5b8db", "#879cc4", "#677795", "#5a6782"]);
  }

  emitWindClick(d)
  {
    console.log(d)
    this.onWindClick.emit(d);
  }

  createSvg(): void
  {
    const radius = Math.min(this.width, this.height) / 2 - 40;

    // append the svg object to the div called figure
    this.svg = d3.select("figure#pie")
        .append("svg")
        .attr("viewBox", "0 0 960 600")
        .append("g")
        .attr("class", "svg-style")
        .attr(
          "transform",
          "translate(" + 960 / 2 + "," + this.height / 2 + ")"
        );


    var outerRadius = this.height / 2 - 20,
        innerRadius = outerRadius / 3,
        cornerRadius = 10;

    // Compute the position of each group on the pie:
    const pie = d3.pie<any>().value((d: any) => Number(d.Stars));
    pie.sort(null);

    // The arc generator
    const arc = d3.arc()
      .innerRadius(radius * 0.5)         // This is the size of the donut hole
      .outerRadius(radius * 0.8)
      .startAngle(function(d) { return d.startAngle + Math.PI/4; })
      .endAngle(function(d) { return d.endAngle + Math.PI/4; });

    // Another arc that won't be drawn. Just for labels positioning
    const outerArc = d3.arc()
      .innerRadius(radius * 0.9)
      .outerRadius(radius * 0.9)
      .startAngle(function(d) { return d.startAngle + Math.PI/4; })
      .endAngle(function(d) { return d.endAngle + Math.PI/4; });

    const label = d3.arc()
        .outerRadius(radius - 40)
        .innerRadius(radius - 40);


    // Build the pie chart
    const _inst = this;
    this.svg
        .selectAll('allSlices')
        .data(pie(this.data))
        .join('path')
        .on("click", function(event, d, nodes)
        {
          _inst.emitWindClick(d)
          let data = _inst.svg.selectAll('path').each(function(e, d)
          {
            e.selected = false;
            d3.select(this).style("fill", "#a5b8db")
            d3.select(this).style("cursor", "default");
          });
          d.selected = true;
          d3.select(this).style("fill", "#5a6782")
          d3.select(this).style("cursor", "pointer");
        })
        .on("mouseover", function(d)
        {
          d3.select(this).style("cursor", "pointer");
          d3.select(this).style("fill","#5a6782")
        })
        .on("mouseout", function(event, d)
        {
          if (!d.selected)
          {
            d3.select(this).style("fill", "#a5b8db")
            d3.select(this).style("cursor", "default");
          }
        })
        .attr('d', arc)
        .attr('fill', d => this.colors(d.data[0]))
        .attr("stroke", "white")
        .style("stroke-width", "2px")
        .style("opacity", 0.7)

    let alldata = d3.select(this.svg).data();
    console.log(alldata)

    this.svg
      .selectAll('allLabels')
      .data(pie(this.data))
      .join('text')
      .text(d => d.data.Framework)
      // .attr("dx", "0.35em")
      .attr('transform', function(d) {
          const pos = outerArc.centroid(d);
          if (d.data.Framework == 'South') pos[1] += 25;
          return `translate(${pos})`;
      })
      .style('text-anchor', function(d) {
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
          if (d.data.Framework == 'North' || d.data.Framework == 'South') return 'middle';
          else return (midangle < Math.PI ? 'start' : 'end')
      })
      .style("font-size", "34px")
  }

}
