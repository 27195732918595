import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GfsComponent } from '@app/landing/products/gfs/gfs.component';

import { FileUploadComponent } from '@app/shared/file-upload/file-upload.component';
import { ProgressComponent } from '@app/shared/file-upload/progress/progress.component';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ModalComponent } from './modal/modal.component';
import { ModalFileComponent } from './modal-file/modal-file.component';

@NgModule({
  declarations: [
    GfsComponent,
    FileUploadComponent,
    ProgressComponent,
    ModalComponent,
    ModalFileComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    FontAwesomeModule
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    GfsComponent,
    ModalComponent,
    ModalFileComponent
  ]
})
export class SharedModule
{
  constructor(library: FaIconLibrary)
  {
      library.addIconPacks(fas);
  }
}
