import { Component, OnInit, Output, Input, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import Amplify, { Storage } from 'aws-amplify';
import { FormGroup, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

    @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;

    @Output() fileUploadEvent = new EventEmitter<any>();
    @Output() fileRemoveEvent = new EventEmitter<any>();

    files: any[] = [];
    message: string = "";


    @Input() filePath: string = "";
    @Input() uploaded_image:any;
    @Input() bucket:string = '';
    @Input() prefix:string = 'images/';
    @Input() filename:string = '';
    @Input() index:number = 0;


    constructor() { }

    ngOnInit(): void
    {
    }

    /**
     * on file drop handler
     */
    onFileDropped($event) {
      this.prepareFilesList($event);
    }

    /**
     * handle file from browsing
     */
    fileBrowseHandler(files) {
      this.prepareFilesList(files);
    }

    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteFile(index: number) {

      if (this.files.length  > 0)
      {
        if (this.files[index].progress < 100)
        {
          console.log("Upload in progress.");
          return;
        }
        this.files.splice(index, 1);
      }
      this.uploaded_image = null;
      this.message = '';
      console.log(this.files);
      this.fileRemoveEvent.emit({index: this.index, file: this.files[index]});
    }

    /**
     * Simulate the upload process
     */
    uploadFilesSimulator(index: number) {
      setTimeout(() => {
        if (index === this.files.length) {
          return;
        } else {
          const progressInterval = setInterval(() => {
            if (this.files[index].progress === 100) {
              clearInterval(progressInterval);
              this.uploadFilesSimulator(index + 1);
            } else {
              // this.files[index].progress += 5;
            }
          }, 200);
        }
      }, 1000);
    }

    async list()
    {
       const files = await Storage.list('images');
       console.log(JSON.stringify(files, null, 2));
     }

    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    async prepareFilesList(files: Array<any>)
    {
      this.message = '';
      for (const item of files) {
        item.progress = 0;
        this.files.push(item);
        console.log(item);
      }

      if (this.files[0].name.toLowerCase().includes('.dat') || this.files[0].name.toLowerCase().includes('.txt'))
      {
        // File is a data file - set filepath to null
        this.filePath = this.files[0].name;
      }
      else
      {
        // Show preview of image
        const reader = new FileReader();
        reader.onload = async () =>
        {
          this.filePath = reader.result as string;
        }
        reader.readAsDataURL(this.files[0]);
      }


      const instance = this;
      try
      {
        let options:any = {
          progressCallback(progress)
          {
            instance.files[0].progress = (progress.loaded/progress.total) * 100;
          },
          Metadata: { 'info': JSON.stringify(this.files[0]) }
        };
        if (this.bucket != '') options.bucket = this.bucket;

        const fileExtension = this.files[0].name.toLowerCase().split('.');
        const filename = this.filename == '' ? this.files[0].name : this.filename + '.' + fileExtension[fileExtension.length -1];
        const result:any = await Storage.put(this.prefix + filename, this.files[0], options);
        if (result.hasOwnProperty('key'))
        {
          let key = result.key;
          const getResult:any = await Storage.get(key);
          this.fileUploadEvent.emit({key: key, index: this.index, filename: this.filename, ext: fileExtension[fileExtension.length -1]});
        }
      }
      catch (error)
      {
        console.log(error)
        if (this.fileDropEl) this.fileDropEl.nativeElement.value = "";
        instance.files[0].progress = 0;
        this.message = error;
      }
    }

    /**
     * format bytes
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) {
        return "0 Bytes";
      }
      const k = 1024;
      const dm = decimals <= 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }
}
