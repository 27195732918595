<div id="pie-chart-parent">
  <ngx-charts-pie-chart
    id="wind-selector"
    class="chart"
    arcWidth="0.40"
    tooltipDisabled="true"
    [customColors]="customColors"
    [activeEntries]="activeEntries"
    [view]="view"
    [scheme]="colorScheme"
    [results]="data"
    [gradient]="gradient"
    [legend]="showLegend"
    [legendPosition]="legendPosition"
    [labels]="showLabels"
    [doughnut]="isDoughnut"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)"
    >
  </ngx-charts-pie-chart>
  <h4 class="d-flex align-self-center justify-content-center position-absolute pr-4 mt-2 w-100">{{ selection }}</h4>
</div>
