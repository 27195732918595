<div class="card shadow rounded-0" style="max-width: 100%">
  <h5 class="card-title mx-4 mt-3"><b>RVC: Rare Vinyl Collector</b></h5>
  <div class="card-img-top">
    <img class="img-fluid" src="/assets/rvc.jpg" alt="@leecampbell" title="@leecampbell" />
  </div>
  <div class="card-body d-flex flex-column">
    <p>
      This service is for those people that spend all their free time scouring the Internet to find that next hidden rare pressing. We think you might need some help.
      That is why we have developed bots that will target specific 2nd hand websites armed with a list of your desirable titles.
      <!-- Best of all is you will get notified everytime our bots find a match, view your best match accuracy and change your searches accordingly. -->

      <!-- To sign up for this service or get more info please <a href="/contact"><u>contact us</u></a>. -->
    </p>
    <!-- <button class="btn btn-outline-dark btn-sm ml-auto mt-auto rounded-0">Find out more</button> -->

    <img src="/assets/bot.svg" style="max-height: 13rem" alt="bot by Arsenty from the Noun Project" />
  </div>
</div>
