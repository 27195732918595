import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-file',
  templateUrl: './modal-file.component.html',
  styleUrls: ['./modal-file.component.scss']
})
export class ModalFileComponent implements OnInit
{

  @Input() multiSelect: boolean;
  @Input() title: string = "Selection";
  @Input() data:any[] = [];
  @Input() icon:boolean = true;
  @Input() acceptLabel: string = '';
  @Input() dismissLabel: string = '';

  @Output() onSelect: EventEmitter<string[]> = new EventEmitter();
  @Output() fileUploadEvent = new EventEmitter<any>();

  @ViewChild('modal') private modalComponent: TemplateRef<ModalFileComponent>;

  private modalRef: NgbModalRef;

  loading: boolean = false;
  complete: boolean = false;

  constructor(
    private _modalService: NgbModal)
    {

    }


  ngOnInit() {

  }

  open(load:boolean = false)
  {
    this.modalRef = this._modalService.open(this.modalComponent);

  }

  close()
  {
    this.modalRef.close();
  }

  dismiss()
  {
    this.modalRef.dismiss();
  }

  fileUploaded(event)
  {
    this.complete = true;
    console.log(event)
    this.fileUploadEvent.emit(event);
  }

}
