<div class="row my-4">
  <div class="col-12 col-sm-4 offset-sm-2 mt-4">
    <h3>What is Isivuno?</h3>
    <p>Isivuno is a data platform used to power digital projects. From mobile apps to art installations we cover a wide variety of applications. It started as a way to provide a foundation for new media art installations which requires data to power interactive experiences. From physical sensors to social media data we built a platform to consolidate and provide the infrastructure to facilitate this type of interaction. </p>
  </div>

  <div class="col-12 col-sm-4 mt-sm-4">
    <h3>What we do</h3>
    <p class="mt-0">We help your creative projects thrive with the right data. Chat to us to see how we can help you gather, transform and deliver the data you need in the eco system of your own choice. Our simple to use API will allow your developers to access what they need, when they need. Don't have a developer? Just get in touch and we'll gladly assist in set-up and managing the custom integrations process for your creative endeavour.</p>
  </div>
</div>
