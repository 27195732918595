import { Component, OnInit, AfterViewInit, Output, EventEmitter, Input  } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-wind-selector',
  templateUrl: './wind-selector.component.html',
  styleUrls: ['./wind-selector.component.scss']
})
export class WindSelectorComponent implements AfterViewInit  {

  view: any[] = [250, 200];

  // options
  gradient: boolean = false;
  showLegend: boolean = false;
  showLabels: boolean = false;
  isDoughnut: boolean = true;
  legendPosition: string = 'below';


  @Input() selection:string = '';

  colorScheme = {
    domain: ["#c7d3ec", "#a5b8db", "#879cc4", "#677795", "#5a6782"]
  };

  data = [
    {"name": "East", "value": "250"},
    {"name": "South", "value": "250"},
    {"name": "West", "value": "250"},
    {"name": "North", "value": "250"},
   ];

  customColors = [
    {"name": "East", "value": "#c7d3ec"},
    {"name": "South", "value": "#a5b8db"},
    {"name": "West", "value": "#879cc4"},
    {"name": "North", "value": "#5a6782"},
  ];

  activeEntries:any[] = [];

  @Output()
  onWindClick:EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void
  {
  }

  ngAfterViewInit()
  {
    let chartElement = document.getElementsByTagName("svg")[2];
    console.log(chartElement)
    let svg  = d3.select(chartElement);
    svg.attr(
      "transform",
      "translate(70, 0) rotate(45)"
    )
    .style("stroke", "white")
    .style("stroke-width", 5);
  }

  onSelect(data): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
    this.activeEntries = [];
    this.activeEntries = [...[JSON.parse(JSON.stringify(data))]];
    this.selection = JSON.parse(JSON.stringify(data)).name;
    this.onWindClick.emit(this.selection);
  }

  onActivate(data): void
  {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
}
