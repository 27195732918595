<div class="row bg-light my-4 py-4 border-top border-bottom justify-content-center">
  <div class="col-12 text-center my-4">
    <h3>~ Products and Services ~</h3>
    <p>Below are some of our products we've built but we'd love to hear from you about yours.<br/> If you have any questions regarding your data driven ideas please do get in touch. </p>
  </div>
  <rvc-component class="col-12 col-sm-3 d-flex align-items-stretch"></rvc-component>
  <div class="col-12 col-sm-3 d-flex align-items-stretch">
    <div class="card shadow rounded-0" style="max-width: 100%">
      <h5 class="card-title mx-4 mt-3"><b>Data Visualization</b></h5>
      <div class="card-img-top position-relative" style="height: 251px">
        <ui-video [thumbnail]="thumbnail" [isplaying]="isplaying" [srcmp4]="videosrc_mp4"></ui-video>
      </div>
      <div class="card-body d-flex flex-column">
          <p> We proud ourselves on spectacular data visualisations. In the cloud, on the cloud or off the cloud. In your foyer or reception area, we build amazing data visualisations from your data. From emergence to just beautiful representations it is the best part of what we do. The above image is taken from our custom built visualisation tool for the Glenfiddich Audio Installation. </p>
          <div style="font-size:80%" class="d-flex flex-column">
            <b>Project</b>
            <a href="https://www.wezside.co.za/work/the-independant-bar" target="_blank">
              <fa-icon [icon]="['fas', 'link']" class="mr-2"></fa-icon><u>Glenfiddich Independent Bar</u>
            </a>
            <b class="mt-2">Date</b> 2017-02-17
            <b class="mt-2">Tech</b> Voice analysis + <u>
              <a href="https://openframeworks.cc/" target="_blank">
                <fa-icon [icon]="['fas', 'link']" class="mr-2"></fa-icon>
                OpenFrameworks.cc
              </a></u>
          </div>
      </div>
    </div>
  </div>
  <gfs-component class="col-12 col-sm-3 d-flex align-items-stretch"></gfs-component>
<!--  <div class="col-12 col-sm-3 d-flex align-items-stretch">
    <div class="card shadow rounded-0" style="max-width: 100%">
      <h5 class="card-title mx-4 mt-3"><b>South African Wine Search</b></h5>
      <div class="card-img-top">
        <img class="img-fluid" src="/assets/wine-sa.jpg" style="max-height: 270px">
      </div>
      <div class="card-body d-flex flex-column">
        <p>We have an extensive wine catalogue including images and barcodes for 1000s of South African Wines.
          We've used these products as part of our Machine Learning explorations.</p>
        <button class="btn btn-outline-dark btn-sm ml-auto mt-auto rounded-0">Find out more</button>
      </div>
    </div>
  </div>-->
</div>
<div class="row" style="height: 2rem">
</div>
