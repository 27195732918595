import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})

export class LocalStorageService
{

  constructor() { }

  setItem(key: string, value: any)
  {
    localStorage.setItem(key, value);
  }

  getItem(key: string, val: any)
  {
    return localStorage.hasOwnProperty(key) ? localStorage.getItem(key) : val;
  }

  setItemFromJSON(key: string, value: any)
  {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getItemAsJSON(key: string, val: any)
  {
    let json = {};
    try
    {
      json = JSON.parse(this.getItem(key, val));
    }
    catch (error)
    {
        console.log(error);
    }
    return json;
  }

  removeItem(key:string)
  {
    localStorage.removeItem(key);
  }

  public clear()
  {
    localStorage.clear();
  }
}
