import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_helpers';
import { PrivacyComponent } from './privacy/privacy.component';
import { ContactComponent } from './contact/contact.component';
import { LandingComponent } from './landing/landing.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { SpotSelectorComponent } from './flow/spot-selector/spot-selector.component';
import { GpxViewerComponent } from './flow/gpx-viewer/gpx-viewer.component';

const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'contact', component: ContactComponent },
  // { path: 'flow', component: SpotSelectorComponent},
  // { path: 'gpx', component: GpxViewerComponent},
  { path: '404', component: NotfoundComponent},
  { path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
