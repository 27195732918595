import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '@app/_services';
import { environment } from '@environments/environment';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor
{
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        // add authorization header with basic auth credentials if available
        const apiUser = this.authenticationService.currentAPIValue;
        if (apiUser && apiUser.authdata)
        {
          // If Paid for then use Paif key
            request = request.clone({
                setHeaders: {
                    ['x-api-key']: `${environment.FREE_API_KEY}`
                }
            });
        }
        else
        {
          request = request.clone({
            setHeaders: {
              ['x-api-key']: `${environment.FREE_API_KEY}`
            }
          });
        }
        return next.handle(request);
    }
}
