  <div class="card border-0 rounded-0" style="max-width: 100%">
    <h5 class="card-title mx-4" [class]="cardTitleClass"><b>{{ title }}</b></h5>
    <div class="card-img-top" *ngIf="showInfo">
      <img class="img-fluid" src="/assets/weather.jpg" style="max-height: 270px">
    </div>
    <div class="card-body d-flex flex-column"  [class]="bodyClass">
      <div *ngIf="showInfo">
          <p>Currently we power a few different apps and provide some historic, present and future weather data. Below is a 5-day forecast for wind direction, min and max gusts for Bloubergstrand, Western Cape, South Africa.</p>
          <h5 style="font-weight: 700" class="mt-2" *ngIf="data.length > 0">
            <fa-icon [icon]="faMapMarkerAlt" class="mr-2"></fa-icon>
            Bloubergstrand: 5-day Forecast
          </h5>
          <span class="small text-muted" style="font-size: 70%"  *ngIf="data.length > 0">(Data below shows the maximum values forecasted between 6AM to 6PM)</span>
      </div>
      <div class="active d-flex flex-row justify-content-between mt-2 mb-4 p-1" style="overflow-x: auto">
        <div class="position-relative" style="left: 50%; top: 50%; transform: translate(-50%, -50%)" *ngIf="loading">
          <div class="gooey">
            <span class="dot"></span>
            <div class="dots">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <span *ngIf="data.length == 0 && !loading" class="mx-auto p-2 text-muted" style="font-size: 80%">No data currently available.</span>
        <!-- Forecast data -->
        <div class="h-100 bg-light border text-center rounded" style="min-width: 4.0rem" *ngFor="let forecast of data">
          <b class="p-2">{{ forecast.dayofweek }}</b>
          <span class="d-block my-1" style="font-size: 70%; border-radius: 0 0 0.25rem 0.25rem">
              {{ forecast?.temp_c?.toFixed(0) }}
              <span *ngIf="forecast.temp_c">°C</span>
          </span>

          <fa-icon [icon]="faArrowCircleUp" class="d-block" [ngStyle]="{'transform': 'rotate(' + (forecast.winddir+180) + 'deg)'}"></fa-icon>
          <span style="font-weight: 700; font-size: 85%" class="d-block mb-2">{{ forecast.winddir_label }}</span>
          <span class="d-block bg-secondary">{{ forecast.windspeed_kn }}</span>
          <span class="bg-primary d-block text-white" style="border-radius: 0 0 0.25rem 0.25rem">{{ forecast.wind_gust_kn }}</span>
        </div>
      </div>
      <div class="text-center mt-4 d-block mt-auto" style="font-size: 60%" *ngIf="showPoweredBy">
          <span> POWERED BY </span>
          <b>ISIVUNO</b>
          <br/>
          <span>Last Updated: {{ last_updated }}</span>
      </div>
      <!-- <button class="btn btn-outline-dark btn-sm ml-auto mt-auto rounded-0">Find out more</button> -->
    </div>
  </div>
