import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { cross } from 'mathjs';
import Amplify, { Storage } from 'aws-amplify';

import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { LocalStorageService } from 'src/app/_services/localstorage.service';
import { IGraphData } from 'src/app/_models/graphdata';
import { colors } from 'src/app/flow/gpx-viewer/data';

import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
import 'leaflet-gpx';


@Component({
  selector: 'app-gpx-viewer',
  templateUrl: './gpx-viewer.component.html',
  styleUrls: ['./gpx-viewer.component.scss']
})
export class GpxViewerComponent implements OnInit {

  map:any | undefined;
  pointsLayer:any | undefined;
  hoverLayer:any | undefined;
  gpxLayer:any |undefined;
  points:any[] = [];
  drawnPoints:any[] = [];
  bearings:any[] = [];
  crossProducts:any[] = [];
  winddir:string | null;
  tabIndex:number = 0;
  colors:any = colors.colorScheme;

  info:any;
  tacks:any[] = [];
  gybes:any[] = [];
  graphData:IGraphData[] = [];

  tackLength:string = '';
  gybeLength:string = '';

  EDIT:boolean = false;
  TRACK_ALPHA:number = 0.2;

  options = {
     layers: [
       L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png', {
      	maxZoom: 20,
      	attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
      })
     ],
     zoom: 20,
     center: L.latLng( -33.9416268, 18.4632142)
   };

  @ViewChild('modal') private modalComponent: ModalComponent;

  constructor(
    private _zone:NgZone,
    private _ls:LocalStorageService
  ) {
    console.log(colors)
   }

  ngOnInit(): void
  {
      // Check for localstorage
      const value = this._ls.getItem('gpx-file', '');
      if (value != '')
      {
          this.fileUploaded({key: value});
      }
      this.winddir = this._ls.getItem('winddir', '');
    }

  async onMapReady(map)
  {
      this.map = map;
      this.pointsLayer = L.layerGroup();
      this.pointsLayer.addTo(this.map);
      this.hoverLayer = L.layerGroup();
      this.hoverLayer.addTo(this.map);
      map.on('zoomend', () =>
      {
      });
      map.on({click: e =>
      {
        if (this.EDIT) this.addManualPoint(e);
      }})
      map.dragging.enable();
  }

  addManualPoint(e)
  {
    this.pointsLayer.clearLayers();
    this.points.push({
      meta: {
        time: new Date().toLocaleTimeString(),
        speed_kn: 0,
        speed: 0
      },
      lat: e.latlng.lat,
      lng: e.latlng.lng,
      distanceTo: 0,
      index: this.points.length
    });
    this.drawGPSReading(this.points);
    this.calcGybesAndTacks();
  }

  openGPXFileLoad()
  {
    this.modalComponent.data.push({key: 0, value: 'Hello'})
    return this.modalComponent.open();
  }


  async fileUploaded(event)
  {
    this._ls.setItem('gpx-file', event.key);
    const result = await Storage.get(event.key);
    this.gpxLayer = new L.GPX(result, {
      async: true,
      joinTrackSegments: false,
      parseElements: ['track'],
      marker_options: {
        startIconUrl: 'assets/pin-icon-start.png',
        endIconUrl: 'assets/pin-icon-end.png',
        shadowUrl: 'assets/pin-shadow.png'
      },
      polyline_options: {
        color: '#7493A8',
        opacity: this.TRACK_ALPHA,
        weight: 3,
        lineCap: 'round'
      }
    }).on('loaded', (e) =>
    {
      this.map.fitBounds(e.target.getBounds());
      this.points = e.target.get_coords();
      console.log(e.target)

      const milliseconds = e.target.get_moving_time();
      const seconds = Math.floor((milliseconds / 1000) % 60);
      const minutes = Math.floor(((milliseconds / (1000*60)) % 60));
      const hours   = Math.floor(((milliseconds / (1000*60*60)) % 24));
      this.info = {
          distance: e.target.get_distance(),
          max_speed:  e.target.get_speed_max(),
          moving_time: `${('00' + hours).slice(-2)}:${('00' + minutes).slice(-2)}:${('00' + seconds).slice(-2)}`,
          avg_temp: e.target.get_average_temp(),
          start_time: e.target.get_start_time(),
          start_time_locale: new Date(e.target.get_start_time()).toLocaleString(),
          end_time: e.target.get_end_time(),
      }

      const len = this.points.length;
      for (let i = 0; i < len; i++)
      {
          this.points[i].meta.time = new Date(this.points[i].meta.time).toLocaleTimeString();
      }
      this.drawGPSReading(this.points);

      if (this.winddir != '')
      {
          this.calcGybesAndTacks();
          this._zone.run(() => this.tabIndex = 1 );
      }
    })
    .on('addpoint', function(e) {
        console.log('Added ' + e.point_type + ' point: ' + e.point);
    })
    .on('error', function(e) {
        console.log('Error loading file: ' + e.err);
      })
    .addTo(this.map);
  }

  distanceTo(lat1, lon1, lat2, lon2)
  {
    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI/180; // φ, λ in radians
    const φ2 = lat2 * Math.PI/180;
    const Δφ = (lat2-lat1) * Math.PI/180;
    const Δλ = (lon2-lon1) * Math.PI/180;

    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ/2) * Math.sin(Δλ/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    const d = R * c; // in metres
    return d;
  }

  drawGPSReading(points:any[])
  {
    const len = points.length;
    this.drawnPoints = [];

    let arr = [];
    for (let i = 0; i < len-1; i++)
    {
      let a = this.points[i];
      let b = this.points[i+1];
      const d = this.distanceTo(a.lat, a.lng, b.lat, b.lng);
      this.points[i].distanceTo = d;
      arr.push(a);
    }

    for (let i = 0; i < len; i++)
    {
      points[i].meta.speed_kn = points[i].meta.speed * 1.94384;
      const c = L.circle(points[i], {
          color: "#233786",
          fillColor: "#233786",
          fillOpacity: this.TRACK_ALPHA,
          radius: 1.0,
          weight: 0
      });
      c.on({
        click: (e) =>
        {
          if (this.drawnPoints[e.target.index])
          {
            this.drawnPoints[e.target.index].selected = true;
            this._zone.run(() =>
            {
              if (document.getElementById('gpx-point-' + i))
              {
                document.getElementById('gpx-point-' + i).scrollIntoView();
                document.getElementById('gpx-point-' + i).focus();
              }
              this.tabIndex = 2;
            })
          }
        }
      });
      this.drawnPoints.push(c);

      c.index = this.drawnPoints.length;
      c.addTo(this.pointsLayer);
    }
  }

  onMouseOverPoint(index)
  {
    if (!this.points[index].meta.cross)
    {
      // this.drawnPoints[index].setRadius(3);
      // this.drawnPoints[index].setStyle({color: '#37454F', weight: 2, fillColor: '#37454F', fillOpacity: 0});
    }
    const c = L.circle(this.points[index], {
      color: "#37454F",
      fillColor: "#37454F",
      fillOpacity: 0,
      radius: 3.0,
      weight: 2
    });
    c.addTo(this.hoverLayer);
  }

  onMouseOutPoint(index)
  {
    this.hoverLayer.clearLayers();
    if (!this.points[index].meta.selected && !this.points[index].meta.cross)
    {
      // this.drawnPoints[index].setRadius(1);
      // this.drawnPoints[index].setStyle({weight: 0, fillColor: '#37454F',  fillOpacity: this.TRACK_ALPHA});
    }
  }

  selectPoint(i)
  {
      this.points[i].meta.selected = true;
      this.onMouseOverPoint(i);
  }

  calculateAngleBetweenAtan2(a, b)
  {
      let dotAB = a[0] * b[0] + a[1] * b[1]; // Dot Product
      let crossAB = cross(b, a);
      let angle = Math.atan2(crossAB[2], dotAB);
      return Math.abs(angle);
  }

  calcGybesAndTacks()
  {
    // Calculate rotation points of lap
    //    - Calculate a direction change via cross product lat/lng
    //    - Calculate gps point direction vector angle to wind direction vector
    //    - Less than 90 is against - Tack
    //    - More than 90 is with the wind vector - Gybe

    const len = this.drawnPoints.length;
    let direction = 0;
    if (len > 2)
    {
      const a = this.drawnPoints[0].getLatLng();
      const b = this.drawnPoints[1].getLatLng();
      const crossProduct = cross([a.lat, a.lng, 0], [b.lat, b.lng, 0]);
      direction =  crossProduct[2] < 0 ? -1 : 1;
    }

    this.graphData = [];
    this.crossProducts = [];
    for (let i = 1; i < len-1; i++)
    {
      const a = this.drawnPoints[i-1].getLatLng();
      const c = this.drawnPoints[i+1].getLatLng();

      const ap = this.drawnPoints[i-1]._point;
      const bp = this.drawnPoints[i]._point;
      const cp = {x: bp.x, y: 0}; // South
      const crossProduct = cross([a.lat, a.lng, 0], [c.lat, c.lng, 0]);
      let currentDir = crossProduct[2] < 0 ? -1 : 1;

      // Create Vectors from GPS points
      let vecA = [bp.x - ap.x, bp.y - ap.y, 0];
      let vecB = [bp.x - cp.x, bp.y - cp.y, 0];

      const ab = this.calculateAngleBetweenAtan2(vecA, vecB);
      const abInDeg = (ab * (180 / Math.PI));
      this.crossProducts[i] = abInDeg;

      if (currentDir != direction)
      {
        direction = currentDir;
        this.points[i].meta.cross = true;
        this.drawnPoints[i].setRadius(2);

        let arr = [];
        if (this.crossProducts[i-1] < 70)
        {
          this.drawnPoints[i].setStyle({color: '#fff', weight: 0, fillColor: '#000550', fillOpacity: 0.8});
          this.tacks.push({
            winddir: direction,
            point: this.points[i]
          });
        }
        if (this.crossProducts[i-1] > 110)
        {
          this.drawnPoints[i].setStyle({color: '#fff', weight: 0, fillColor: '#E600FF', fillOpacity: 0.8});
          this.gybes.push({
            windir: direction,
            point: this.points[i]
          });
        }
      }
    }
    this.graphData.push({
      id: this.graphData.length,
      name: 'Tacks',
      value: this.tacks.length
    } as IGraphData)

    this.graphData.push({
      id: this.graphData.length,
      name: 'Gybes',
      value: this.gybes.length
    } as IGraphData)

    this.gybeLength = ('00' + this.gybes.length).slice(-2);
    this.tackLength = ('00' + this.tacks.length).slice(-2);


    // this.tabIndex = 1;
    //    - calculate rotation direction CW or CCW using cross product of point 1 and 3
    //    - determine tack or gybe
  }

  calcBearing(start:L.latLng, end: L.latLng)
  {
    const λ1 = start.lat;
    const λ2 = end.lat;
    const φ1 = start.lng;
    const φ2 = end.lng;

    // where	φ1,λ1 is the start point, φ2,λ2 the end point (Δλ is the difference in longitude)
    const y = Math.sin(λ2-λ1) * Math.cos(φ2);
    const x = Math.cos(φ1)*Math.sin(φ2) -
              Math.sin(φ1)*Math.cos(φ2)*Math.cos(λ2-λ1);
    const θ = Math.atan2(y, x);
    const brng = (θ*180/Math.PI + 360) % 360; // in degrees
    return brng;
  }

  reset()
  {
    for (let i = 0; i < this.drawnPoints.length-1; i++)
    {
      this.drawnPoints[i].selected = false;
    }
    this.winddir = null;
    this.graphData = [];
    this.tacks = [];
    this.gybes = [];
    this.drawnPoints = [];
    this.points = [];
    this.pointsLayer.clearLayers();
    this.gpxLayer.clearLayers();
    this._ls.setItem('gpx-file', '')
  }


    onWindClick(event)
    {
      this.winddir = event;
      this._ls.setItem('winddir', this.winddir);
    }

}
