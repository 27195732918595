import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireAuthModule } from '@angular/fire/auth';
import firebase from 'firebase/app';
import {
  FirebaseUISignInFailure,
  FirebaseUISignInSuccessWithAuthResult,
  NativeFirebaseUIAuthConfig,
} from 'firebaseui-angular/esm2015/lib/firebaseui-angular-library.helper';

import { User } from '@app/_models';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService
{
    public apiSubject: BehaviorSubject<User>;
    private currentUserSubject: BehaviorSubject<User>;

    public apiUser: Observable<User>;
    public currentUser: Observable<User>;
    public isAnonymous: Boolean;

    constructor(private auth: AngularFireAuth)
    {
        // API user
        this.apiSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('apiUser')));
        this.apiUser = this.apiSubject.asObservable();

        // User
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();


        // this.auth.authState.subscribe(user =>
        // {
        //     console.log(user);
        //     // this.apiSubject.next(user);
        // });

        this.apiUser.subscribe(x =>
        {
          // console.log('[AuthenticationService][apiUser] ', x);
          if (x == null)
          {
            console.log('[AuthenticationService][apiUser] Refresh needed...');
            this.refreshUser();
          }
          else
          {
            console.log('[AuthenticationService][apiUser] Session is valid');
          }
        })
    }

    refreshUser()
    {
      console.log('refreshUser');
      let user = this.auth.currentUser.then(u =>
      {
        // If Firebase User exist then just get the token again to trigger
        // the observables which will make the API call
        console.log('[AuthenticationService] Firebase User => ', u)
        if (u == null)
        {
          this.initAuthState();
          this.auth.signInAnonymously().catch(e => console.log(e));
        }
        else this.fetchUserToken(u);
      })
      .catch(e =>
      {
        console.error('[AuthenticationService][currentuser][error]', e);
      })
    }

    initAuthState()
    {
      this.auth.authState.subscribe(data =>
      {
        // console.log('[*AuthenticationService][authState]', data)
        this.firebaseAuthChangeListener(data);
      });
    }

    onCompleteHandler(res)
    {
      console.log(res);
    }

    errorHandler(err)
    {
      console.log('[AuthenticationService]' + err);
    }

    get currentUserValue(): User
    {
      return this.currentUserSubject.value;
    }

    get currentAPIValue(): User
    {
      return this.apiSubject.value;
    }

    logout()
    {
        this.auth.signOut();
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    successCallback(signInSuccessData: FirebaseUISignInSuccessWithAuthResult)
    {
      console.log(signInSuccessData.authResult.user);
      this.fetchUserToken(signInSuccessData.authResult.user);
      return signInSuccessData.authResult.user;
    }

    errorCallback(errorData: FirebaseUISignInFailure)
    {
      console.log('errorCallback')
    }

    uiShownCallback()
    {
      console.log('uiShownCallback')
    }

    fetchUserToken(fuser:firebase.User)
    {
      fuser.getIdToken(true).then((result) =>
      {
        let user = new User();
        user.displayName = fuser.displayName;
        user.uid = fuser.uid;
        user.authdata = window.btoa(result);
        user.isAnonymous = fuser.isAnonymous;
        console.log('fetchUserToken', user.isAnonymous);
        if (!user.isAnonymous)
        {
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
        }
        else
        {
          console.log('[AuthenticationService] API User saved');
          localStorage.setItem('apiUser', JSON.stringify(user));
          this.apiSubject.next(user);
        }
      })
      .catch(function(err)
      {
        console.log(err);
        return err;
      });
    }

    private firebaseAuthChangeListener(response)
    {
      // if needed, do a redirect in here
      if (response)
      {
        console.log('[AuthenticationService] Logged in :)');
        let fuser: firebase.User = response;
        console.log(fuser.uid)

        // Check if there was a previous call that returned a 401
        // Then allow the completion of a new token for future use
        console.log('fuser', fuser)
/*        if (this.currentAPIValue != null && fuser.uid == this.currentAPIValue.uid && fuser.isAnonymous)
        {
          console.log('Tokens are the same >> skip!');
          return;
        }*/

        // Get a new token
        this.fetchUserToken(fuser);
      }
      else
      {
        console.log('Logged out :(');

        // Sign in anonymously to get access to API
        localStorage.removeItem('apiUser');
        this.currentUserSubject.next(null);
        // this.apiSubject.next(null);
        return null;
      }
    }
}
